div.field {
  margin-bottom: 20px;
}

.fileInput {
  display: none;
}

td.placeholder {
  color: grey;
  font-style: italic;
}

th.fieldHeader {
  color: grey;
}

td.fieldHeader {
  color: grey;
}

td.field {
  text-align: right;
}
