.secondaryLabel {
  font-size: 12px;
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 10px;
}

.secondaryRow {
  display: flex;
}

label.switchAssigned {
  display: flex;
  justify-self: right;
}
