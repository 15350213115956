.subheader > div {
  margin-right: 5px;
}

div.chip {
  border: none;
}

fieldset.checkboxField {
  margin-top: 20px;
  display: block;
}

button.submitButton {
  margin-left: 8px;
}
