.universeCard {
  margin-top: 20px;
  margin-bottom: 20px;
}

.actionsContainer {
  flex-grow: 1;
}

.actionsContainer > :not(:first-child) {
  margin-left: 8px;
}

div.toolbar {
  min-height: 0px;
}

.toolbarLabel {
  margin-left: 5px;
  margin-right: 10px;
}

div.chip {
  border: none;
}

div.secondaryChip {
  border: none;
  color: #606060;
}
