.listCard {
  margin-top: 20px;
  margin-bottom: 20px;
}

.actionsContainer {
  flex-grow: 1;
}

.actionsContainer > :not(:first-child) {
  margin-left: 8px;
}

div.chip {
  border: none;
}
