div.field {
  margin-bottom: 20px;
}

.formContainer {
  margin-top: 10px;
}

div.gridContainer {
  margin-top: 20px;
}

div.selectionContainer {
  height: 100%;
  min-height: 120px;
}

div.filterStatusContainer {
  display: flex;
  align-items: center;
}

div.filterDrawer {
  margin-top: 20px;
  padding: 10px;
}

div.filterResults {
  margin-left: 12px;
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
}

div.filterActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

button.applyButton {
  margin-left: 10px;
}

div.totalMarkers {
  height: 20px;
  color: grey;
  margin-right: 5px;
}

span.totalVoters {
  font-size: 1.2em;
}

span.voters {
  margin-right: 4px;
  font-size: 1.1em;
}

span.households {
  font-size: 1.1em;
}

label.switch span {
  font-size: 0.875rem;
}
