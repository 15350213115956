div.filterPaper {
  margin-top: 20px;
  padding: 10px;
}

div.filterStatus {
  flex: 1;
}

div.filterResults {
  display: flex;
  align-items: center;
  height: 100%;
}

div.filterActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

button.applyButton {
  margin-left: 10px;
}

div.totalMarkers {
  height: 20px;
  color: grey;
  margin-right: 5px;
}
