h6.headerLabel {
  flex-grow: 1;
}

a.cancelButton {
  margin-right: 8px;
}

div.field {
  margin-bottom: 20px;
}

.questionCard {
  margin-bottom: 16px;
}

.fileInput {
  display: none;
}

.uploadLabel {
  margin-left: 10px;
}
