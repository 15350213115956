div.questionField {
  padding-right: 16px;
  padding-bottom: 16px;
}

input.textField {
  color: rgb(0, 0, 0);
}

div.textField {
  color: rgb(0, 0, 0);
}

div.answerField:last-child {
  margin-bottom: 0px;
}

div.answerField {
  margin-bottom: 16px;
}

button.removeButton {
  margin-right: 8px;
}

label.identificationPurposeLabel {
  margin-left: 8px;
  margin-top: 8px;
}
