.drawer {
  width: 220px;
  flex-shrink: 0;
}

.drawerPaper {
  width: 220px;
}

.drawerContainer {
  overflow: auto;
}
