div.field {
  margin-bottom: 20px;
}

fieldset.checkboxField {
  margin-top: 20px;
  display: block;
}

legend.checkboxField {
  margin-bottom: 14px;
}

h6.headerLabel {
  flex-grow: 1;
}

.fileInput {
  display: none;
}

.uploadLabel {
  margin-left: 10px;
}
