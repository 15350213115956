div.menuItemText {
  display: flex;
  width: 100%;
  font-size: 14px;
}

div.secondaryText {
  flex: 1;
  align-self: center;
  text-align: right;
  color: grey;
  font-size: small;
}

span.selectedLength {
  color: grey;
}

span.voters {
  font-size: 1.2em;
  padding-top: 3px;
}
